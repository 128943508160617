import * as bootstrap from 'bootstrap';
import jquery from 'jquery';

export default (window.$ = window.jQuery = jquery);

$(function () {
    $(window).scroll(function() 
    {
    if ($(this).scrollTop() > 200)
    {
    $('.c-topnav').addClass("sticky-top");
    }
    else
    {
    $('.c-topnav').removeClass("sticky-top");
    }
    });
})
